import * as React from "react"

import { useDiscountProduct } from "@app/features/product/store/hooks"

const timeDiscount = (discount) => {
  const { $showDiscountPhotoBook, $hideDiscountPhotoBook } = useDiscountProduct()
  const percent = discount ? discount?.percent : null
  const finishAt = discount ? discount?.finishAt : null // Example: "2023-05-05 13:21:00"
  const nowDate = Date.parse(new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" })) // new Date().getTime()
  const countDownDate = Date.parse(finishAt) // new Date(finishAt).getTime()

  const [time, setTime] = React.useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  const [isVisibleDiscount, setIsVisibleDiscount] = React.useState(false)

  const timeChange = (countDownDate) => {
    const timer = setInterval(function () {
      const nowDate = Date.parse(new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" }))
      const distance = countDownDate - nowDate

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setTime({ days, hours, minutes, seconds })
      if (countDownDate) setIsVisibleDiscount(true)

      if (distance < 0) {
        clearInterval(timer)
        setIsVisibleDiscount(false)
        $hideDiscountPhotoBook()
      }
    }, 1000)
  }

  React.useEffect(() => {
    if (finishAt) timeChange(countDownDate)

    if (countDownDate - nowDate > 0) {
      $showDiscountPhotoBook()
    } else {
      $hideDiscountPhotoBook()
    }
  }, [countDownDate])

  return { percent, time, isVisibleDiscount }
}

export { timeDiscount }
