import cn from "clsx"
import { FC, ReactNode } from "react"

import styles from "./old-price.module.scss"

type TOldPriceProps = {
  children: ReactNode
  className?: string
}

const OldPrice: FC<TOldPriceProps> = ({ children, className = "" }) => {
  return <span className={cn(styles["root"], className)}>{children}</span>
}

export { OldPrice }
