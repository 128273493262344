const getCaptionFontSize = (ref, countLetter) => {
  const rootWidth = ref.current ? ref.current.offsetWidth : 1
  const coeff190 = 0.06735751 // из макета: 193px ширина caption из и font-size: 13px -> 13/193
  const coeff240 = 0.08595855 // из макета: 193px ширина caption из и font-size: 16.59px -> 16.59/193

  if (countLetter === 190) return rootWidth * coeff190

  return rootWidth * coeff240
}

export { getCaptionFontSize }
