import { useProductGroups } from "@app/contexts/product-groups"

const getProductDiscountData = (productHandle) => {
  const { groups } = useProductGroups()
  const photoBookData = groups ? groups.find((item) => item.handle === productHandle) : null

  return photoBookData?.discount
}

export { getProductDiscountData }
