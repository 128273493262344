import { Star } from "@app/ui/icons"

import styles from "./rating.module.scss"

type TRatingProps = {
  value: number
}

const Rating = ({ value }: TRatingProps) => {
  const getStarWidth = (currentStarIndex: number, value: number): number => {
    let left = value - currentStarIndex

    if (left <= 0) return 0
    if (left >= 1) return 100
    if (left < 1) return left * 100
  }

  return (
    <div className={styles["root"]}>
      {[0, 1, 2, 3, 4].map((currentStar) => {
        return (
          <span key={currentStar} className={styles["star"]}>
            <Star
              width={14}
              height={14}
              className={styles["star-icon"]}
              fill="var(--color-silver-lake)"
              stroke="var(--color-silver-lake)"
            />
            <Star
              width={14}
              height={14}
              className={styles["star-icon"]}
              style={{ width: `${getStarWidth(currentStar, value)}%` }}
              fill="var(--color-bravo)"
              stroke="var(--color-bravo)"
            />
          </span>
        )
      })}
    </div>
  )
}

export { Rating }
