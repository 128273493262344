import React, { FunctionComponent } from "react"

import cn from "clsx"

import { Rating } from "@app/ui/rating"

import styles from "./rating-info.module.scss"

type TRatingInfoProps = {
  rating: number
  before?: any
  after?: any
  beforeClassName?: string
  afterClassName?: string
}

const RatingInfo: FunctionComponent<TRatingInfoProps> = ({
  before,
  after,
  rating,
  beforeClassName = "",
  afterClassName = "",
}: TRatingInfoProps) => {
  return (
    <div className={styles["root"]}>
      {before && <div className={cn(styles["before"], beforeClassName)}>{before}</div>}
      <Rating value={rating} />
      {after && <div className={cn(styles["after"], afterClassName)}>{after}</div>}
    </div>
  )
}

export { RatingInfo }
