import { useRouter } from "next/router"
import { useGallery } from "@app/contexts"

import { Button } from "@app/ui/button"
import { Modal } from "@app/ui/modal"

import styles from "./locked-modal.module.scss"
import { photoBookProductVisitsIncrement } from "@app/features/statistics"

type TLockedModal = {
  isVisible: boolean
  onClose: () => void
}

const LockedModal = ({ isVisible, onClose }) => {
  const { gallery } = useGallery()
  const router = useRouter()

  const handleOrderClick = () => {
    photoBookProductVisitsIncrement(gallery.id)
    router.push(`/gallery/${gallery.url}/product/book/`)
  }

  return (
    <Modal open={isVisible} onClose={onClose}>
      <h2 className={styles["title"]}>Спасибо за ваш интерес</h2>
      <div className={styles["description"]}>
        Сейчас этот товар находится в разработке, но у вас есть возможность заказать фотокнигу.
      </div>
      <Button onClick={() => handleOrderClick()}>заказать книгу</Button>
    </Modal>
  )
}

export { LockedModal }
