import { useDiscountStore, IState } from "features/product/store"

const isDiscountPhotoBookActiveSelector = (state: IState) => state.isDiscountPhotoBookActive
const showDiscountPhotoBookSelector = (state: IState) => state.showDiscountPhotoBook
const hideDiscountPhotoBookSelector = (state: IState) => state.hideDiscountPhotoBook

function useDiscountProduct() {
  const $isDiscountPhotoBookActive = useDiscountStore(isDiscountPhotoBookActiveSelector)
  const $showDiscountPhotoBook = useDiscountStore(showDiscountPhotoBookSelector)
  const $hideDiscountPhotoBook = useDiscountStore(hideDiscountPhotoBookSelector)

  return {
    $isDiscountPhotoBookActive,
    $showDiscountPhotoBook,
    $hideDiscountPhotoBook,
  }
}

export { useDiscountProduct }
