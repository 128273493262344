import create, { State } from "zustand"

export interface IState extends State {
  isDiscountPhotoBookActive: boolean
  showDiscountPhotoBook: () => void
  hideDiscountPhotoBook: () => void
}

const useDiscountStore = create<IState>((set) => ({
  isDiscountPhotoBookActive: false,
  showDiscountPhotoBook: () => set(() => ({ isDiscountPhotoBookActive: true })),
  hideDiscountPhotoBook: () => set(() => ({ isDiscountPhotoBookActive: false })),
}))

export { useDiscountStore }
