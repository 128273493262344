import React from "react"

const useCoverRatio = (cover) => {
  const [ratio, setRatio] = React.useState(null)

  React.useEffect(() => {
    const img = new Image()
    img.addEventListener("load", function () {
      this.naturalWidth < this.naturalHeight ? setRatio("vertical") : setRatio("horizontal")
    })

    img.addEventListener("error", function () {
      setRatio("horizontal")
    })

    img.src = cover?.sm
  }, [cover])

  return ratio
}

export { useCoverRatio }
